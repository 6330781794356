<template>
    <div>
        <fieldset>
            <div class="text-blue legend">
                {{ $t("serviceTicket.repairItem") }}
                <Tooltip placement="top">
                    <Icon type="ios-information-circle-outline" size="17" />
                    <div slot="content">
                        <div class="tw-text-xs">
                            {{ $t("serviceTicket.repairItem") }}
                        </div>
                    </div>
                </Tooltip>
            </div>

            <div class="tw-mt-2 overflow-auto tw-max-h-40" style="min-height: 400px">
                <div class="table-ui-detail">
                    <table class="tw-w-full tw-mt-2">
                        <thead>
                            <tr>
                                <th class="tw-uppercase tw-border tw-text-center bg-header-main tw-border-gray-300 "
                                    style="width: 2%">
                                    {{ $t("no") }}
                                </th>
                                <th class="tw-uppercase tw-border tw-text-left bg-header-main tw-border-gray-300 required"
                                    style="width: 18%">
                                    {{ $t("serviceTicket.product") }}
                                </th>
                                <th class="tw-uppercase tw-border tw-text-left bg-header-main tw-border-gray-300"
                                    style="width: 18%">
                                    {{ $t("serviceTicket.serial") }}
                                </th>
                                <th class="tw-uppercase tw-border tw-text-center bg-header-main tw-border-gray-300 required"
                                    style="width: 135px">
                                    {{ $t("serviceTicket.uom") }}
                                </th>
                                <th class="tw-uppercase tw-border tw-text-center bg-header-main tw-border-gray-300 required"
                                    style="width: 150px">
                                    {{ $t("serviceTicket.qty") }}
                                </th>
                                <th class="tw-uppercase tw-border tw-text-center bg-header-main tw-border-gray-300"
                                    style="width: 200px">
                                    {{ $t("serviceTicket.warrantyExpiryDate") }}
                                </th>
                                <th class="tw-uppercase tw-border tw-text-center bg-header-main tw-border-gray-300"
                                    style="width: 200px">
                                    {{ $t("serviceTicket.repairedBy") }}
                                </th>
                                <th class="tw-uppercase tw-border tw-text-center bg-header-main tw-border-gray-300">
                                    {{ $t("serviceTicket.remarks") }}
                                </th>
                                <th class="tw-uppercase tw-border tw-text-center bg-header-main tw-border-gray-300"
                                    style="width: 2%">
                                    <a href="#" class="tw-text-white" @click.prevent="addNewItem">
                                        <i class="fas fa-plus"></i>
                                    </a>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in model.product_list" :key="index">
                                <td>
                                    <input type="text" :value="index + 1"
                                        class="tw-w-full tw-outline-none tw-text-center" readonly />
                                </td>
                                <td>
                                    <input type="text" v-model="item.product_name"
                                        :placeholder="$t('serviceTicket.product')" class="form-control" :class="{
                                            'is-invalid': errors.has(
                                                'product_list.' +
                                                index +
                                                '.product_name'
                                            )
                                        }" />
                                </td>
                                <!-- <td>
                                    <Select
                                        v-model="item.product_id"
                                        :filter-by-label="true"
                                        :placeholder="$t('select')"
                                        :clearable="true"
                                        filterable
                                        @on-change="onChangeProduct"
                                    >
                                        <Option
                                            v-for="product in productList"
                                            :value="product.product_id"
                                            :key="product.product_id"
                                            :label="product.product_name_en"
                                        >
                                            {{ product.product_name_en }}
                                        </Option>
                                    </Select>
                                </td> -->
                                <td>
                                    <input type="text" v-model="item.serial_no"
                                        :placeholder="$t('serviceTicket.serial')" class="form-control" :class="{
                                            'is-invalid': errors.has(
                                                'product_list.' +
                                                index +
                                                '.serial_no'
                                            )
                                        }" />
                                </td>
                                <td>
                                    <Select v-model="item.uom_id" :placeholder="$t('select')" class="tw-text-center"
                                        :class="{
                                            'is-invalid': errors.has(
                                                'product_list.' +
                                                index +
                                                '.uom_id'
                                            )
                                        }">
                                        <Option v-for="uom in getUom(
                                            item.product_id
                                        )" :value="uom.uom_id" :key="uom.uom_id" :label="uom.uom_name_en">
                                            {{ uom.uom_name_en }}
                                        </Option>
                                    </Select>
                                </td>
                                <td>
                                    <input type="number" v-model.number="item.quantity"
                                        class="form-control tw-text-center" :class="{
                                            'is-invalid': errors.has(
                                                'product_list.' +
                                                index +
                                                '.quantity'
                                            )
                                        }" placeholder="Qty" />
                                </td>
                                <td class="tw-py-1">
                                    <DatePicker :value="item.warranty_expiry_date" style="width: 100%"
                                        placeholder="DD-MM-YYYY" format="dd-MM-yyyy"></DatePicker>
                                </td>
                                <td>
                                    <Select v-model.number="item.repaired_by" :filter-by-label="true"
                                        :placeholder="$t('select')" :clearable="true" filterable class="tw-text-center">
                                        <Option v-for="employee in employeeList" :value="employee.employee_id"
                                            :key="employee.employee_id" :label="employee.employee_name_en">
                                            {{ employee.employee_name_en }}
                                        </Option>
                                    </Select>
                                </td>
                                <td>
                                    <input type="text" v-model="item.remarks" :placeholder="$t('serviceTicket.remarks')"
                                        class="form-control" />
                                </td>
                                <td class="tw-text-center tw-align-middle">
                                    <a href="#" class="text-danger" @click.prevent="removeItem(item)">
                                        <i class="fa fa-trash"></i>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </fieldset>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { Errors } from "form-backend-validation";
import { clone } from "lodash";

export default {
    name: "ItemDetails",
    props: ["value", "validate"],
    data() {
        return {
            errors: new Errors(),
            show_modal: false,
            model: {},
            newItem: {
                product_id: null,
                product_name: null,
                serial_no: null,
                uom_id: null,
                quantity: 1,
                warranty_expiry_date: null,
                repaired_by: null,
                completion_date: null,
                remarks: null
            }
        };
    },
    created() {
        this.model = this.value;
    },
    computed: {
        ...mapState("customerService/serviceTicket", [
            "formViewDatas",
            "edit_data"
        ]),
        ...mapGetters("customerService/serviceTicket", [
            "getUom",
            "isDefaultUom"
        ]),
        productList() {
            return this.formViewDatas.product;
        },
        employeeList() {
            return this.formViewDatas.employee
        },
    },
    methods: {
        onChangeProduct(value) {
            let uom_id = this.isDefaultUom(value);
            this.model.product_list.find(
                el => el.product_id == value
            ).uom_id = uom_id;
            return value;
        },
        addNewItem() {
            this.model.product_list.push(clone(this.newItem));
        },
        removeItem(item) {
            this.model.product_list.splice(
                this.model.product_list.indexOf(item),
                1
            );
        }
    },
    watch: {
        validate: function (error) {
            this.errors = new Errors(error.errors);
        }
    }
};
</script>
<style scoped>
.legend {
    position: absolute;
    z-index: 200;
    top: 0;
    font-size: 12px;
    line-height: 1;
    margin: -13px 0 0;
    /* half of font-size */
    background: #fff;
    border: 1px solid #ddd;
    width: auto;
    padding: 5px 14px;
    box-shadow: -1px 4px 27px -7px #bdbdbd;
    border-radius: 3px;
    font-weight: bold;
}

.bg-header-main {
    background: #3990e0;
    color: white;
}

.form-control {
    height: 32px !important;
}

textarea::placeholder,
input::placeholder {
    color: #c5c8ce !important;
}
</style>
<style>
.is-invalid .ivu-select-selection {
    border: 1px solid red !important;
}

.is-invalid .ivu-select-selection .ivu-select-placeholder,
.is-invalid .ivu-select-selection input::placeholder,
.is-invalid .ivu-icon {

    color: red !important;
}
</style>